<template>
  <div style="display: inline-block; white-space: nowrap;">
    <span
      v-b-tooltip.hover
      :title="gridProps.row.temDarf ? 'Visualizar DARF' : 'Guia indisponível no momento'"
    >
      <b-btn
        variant="none"
        class="btn-none icone-acao m-0 p-0"
        :disabled="!gridProps.row.temDarf"
        @click="abrirArquivo(gridProps.row, true)"
      >
        <font-awesome-icon
          icon="fa-solid fa-barcode"
          class="text-primary"
        />
      </b-btn>
    </span>
    <b-btn
      v-if="gridProps.row.situacao != 'Motivo'"
      v-b-tooltip.hover
      variant="none"
      title="Enviar por email"
      class="btn-none icone-acao m-0 p-0"
      @click="openModalEnviarDctfWeb(gridProps.row.id)"
    >
      <feather-icon
        icon="SendIcon"
        class="text-primary"
        size="20"
      />
    </b-btn>
    <b-btn
      v-b-tooltip.hover
      variant="none"
      title="Declarações"
      class="btn-none icone-acao m-0 p-0"
      @click="openModalDeclaracoes(gridProps.row.id)"
    >
      <font-awesome-icon
        icon="fa-sharp fa-solid fa-list"
        class="text-primary"
      />
    </b-btn>
    <span
      v-b-tooltip.hover
      :title="gridProps.row.temRecibo ? 'Visualizar recibo' : 'Recibo indisponível no momento'"
    >
      <b-btn
        variant="none"
        class="btn-none icone-acao m-0 p-0"
        :disabled="!gridProps.row.temRecibo"
        @click="abrirArquivo(gridProps.row, false)"
      >
        <font-awesome-icon
          icon="fa-sharp fa-regular fa-clipboard"
          class="text-primary"
        />
      </b-btn>
    </span>

    <atualizacao-manual
      tipo-rotina="DCTFWeb"
      :enviar-atualizacao="atualizarManual"
      @atualizar-grid="$emit('atualizar-grid')"
    />

    <visualizar-arquivo
      v-if="mostarArquivo && arquivo.url"
      :url="arquivo.url"
      :observacao="arquivo.observacao"
      @hidden="fecharArquivo()"
    />

    <envio-email
      ref="modalEnvioEmail"
      :title="`Enviar Declaração DCTFWeb ${gridProps.row.razaoSocial}`"
      :sugestao="gridProps.row.emailEmpresa"
      :sugestao-email-adicional="gridProps.row.emailAdicionalEmpresa"
      @enviarEmail="enviarDctfWeb($event.itensEmail, $event.id)"
    />

    <dctf-webs-empresa
      :ref="`modalDctfWebEmpresa${gridProps.row.id}`"
      title="DCTFWeb"
      :declaracao="gridProps.row"
      :request-declaracao="getDctfWebEmpresas"
      @envioEmail="openModalEnviarDctfWeb($event.item.id)"
      @mostrarGuia="abrirArquivo($event.item, $event.isDarf)"
    />
  </div>
</template>

<style lang="scss">
</style>

<script>
import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '../../services/api'

export default {
  components: {
    FontAwesomeIcon,
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    DctfWebsEmpresa: () => import('../dctf-webs-Empresa/DctfWebsEmpresa.vue'),
    AtualizacaoManual: () => import('@/app/shared/components/botoes-inline/atualizacao-manual/AtualizacaoManual.vue'),
  },
  props: {
    gridProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mostarArquivo: false,
      arquivo: {},
      mostrarPendencias: false,
      dctfWebSelecionada: null,
    }
  },
  methods: {
    atualizarManual() {
      return api.atualizarManual({
        empresasId: [this.gridProps.row.empresaId],
        competencia: this.gridProps.row.periodoApuracao,
      })
        .then(payload => {
          this.verificarRetornos(payload.data)
        })
        .catch(err => {
          this.verificarRetornos(err.response.data)
          throw err
        })
    },
    verificarRetornos(result) {
      if (result === 1) {
        this.$sucesso('Atualizada com sucesso!')
      } else if (result === 3) {
        this.openModalErro('Limite atingido', 'O limite de emissão diária foi atingido para todos os certificados válidos disponíveis. Aguarde a liberação de novas consultas.')
      } else if (result === 4) {
        this.openModalErro('Nenhum certificado encontrado', 'Nenhum certificado válido foi encontrado. A atualização manual não pode ser realizada.')
      } else if (result === 5) {
        this.$erro('Saldo insuficiente para concluir a operação.')
      } else if (result === 6) {
        this.$erro('Procuração eletrônica - Empresa apresenta erro com a procuração eletrônica. Verifique e corrija para evitar cobranças indesejadas e erros nas atualizações das rotinas.')
      } else if (result) {
        this.$erro(`Não foi possível fazer a atualização. Tente novamente mais tarde!. Erro: ${result}`)
      } else {
        this.notificarErro('Houve um problema com a atualização. Tente novamente mais tarde!')
      }
    },
    openModalErro(title, html) {
      this.$swal({
        title: `<div class="text-danger">${title}!</div>`,
        html: `<div class="text-primary">${html}</div>`,
        icon: 'warning',
        iconColor: '#ea5455',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-outline-danger mr-50',
        },
        buttonsStyling: false,
      })
    },
    getDctfWebEmpresas(empresaId) {
      return api.getDctfWebsEmpresa(empresaId)
    },
    abrirArquivo(dctfWeb, isDarf) {
      if ((dctfWeb.temDarf && isDarf) || (dctfWeb.temRecibo && !isDarf)) {
        api.getURLDctfWeb(dctfWeb.id, isDarf)
          .then(payload => {
            this.arquivo.url = payload.data
            this.mostarArquivo = true
          })
          .catch(err => {
            this.fecharArquivo()
            this.notificarErro('Não foi possível abrir a visualização de arquivo.', err)
            throw err
          })
      } else {
        Vue.swal({
          title: 'Não possui Arquivo',
          text: 'Não há documento para impressão.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-outline-primary mr-50',
          },
          buttonsStyling: false,
        })
      }
    },
    fecharArquivo() {
      this.mostarArquivo = false
      this.dctfWebSelecionada = null
      this.arquivo = {}
    },
    openModalDeclaracoes(id) {
      this.$refs[`modalDctfWebEmpresa${id}`].show()
    },
    fecharDctfWebEmpresa() {
      this.mostrarPendencias = false
      this.dctfWebSelecionada = null
    },
    notificarErro(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    },
    openModalEnviarDctfWeb(id) {
      this.$refs.modalEnvioEmail.idEntidade = id
      this.$refs.modalEnvioEmail.abrirModal()
    },
    enviarDctfWeb(itens, id) {
      if (itens.emailsPara.length === 0 && itens.emailsCc === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Informe ao menos um e-mail',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        api.notificaDctfWeb(this.gridProps.row.razaoSocial, itens.emailsPara, itens.emailsCc, id, itens.mensagensAdicional, itens.definirEmailPadroes)
      }
    },
  },
}
</script>
